import * as amplitude from '@amplitude/analytics-browser'
import { BaseEvent } from '@amplitude/analytics-types'
import { isServer } from '@mr-yum/frontend-core/dist/support/env'
import { useFlag, useFlagsStatus } from '@unleash/proxy-client-react'
import {
  FLAG_ALLOW_POSITIONING_INFO_BLOCKS,
  FLAG_NEW_REDEEM_POINTS_VALUE,
  FLAG_POINTS_TO_USE_POLLING,
  FLAG_REWARDS_BALANCE,
  FLAG_REWARDS_PRICE,
} from 'common/constants'
import { useEonxEnvContext } from 'components/EonX/EonxEnv'
import { CurrentUserDocument, Partner } from 'gql/graphql'
import { config } from 'lib/config'
import React, { ReactNode, useCallback, useEffect } from 'react'
import { useQuery } from 'urql'

const AMPLITUDE_API_KEY = config.amplitudeKey

if (!isServer) {
  amplitude.init(AMPLITUDE_API_KEY, {
    defaultTracking: false,
  })
}

export const AmplitudeIdentify = ({ children }: { children: ReactNode }) => {
  const { flagsReady } = useFlagsStatus()
  const { isEonxEnv } = useEonxEnvContext()

  const rewardsPrice = useFlag(FLAG_REWARDS_PRICE)
  const rewardsBalance = useFlag(FLAG_REWARDS_BALANCE)
  const allowPositioningOfInfoBlocks = useFlag(
    FLAG_ALLOW_POSITIONING_INFO_BLOCKS,
  )
  const newRedeemPointsValue = useFlag(FLAG_NEW_REDEEM_POINTS_VALUE)
  const pointsToUseShouldPoll = useFlag(FLAG_POINTS_TO_USE_POLLING)

  const [{ data }] = useQuery({
    query: CurrentUserDocument,
    pause: isServer,
  })

  const userId = data?.currentUser?.id

  useEffect(() => {
    amplitude.setUserId(userId)
  }, [userId])

  useEffect(() => {
    if (flagsReady) {
      const identifyEvent = new amplitude.Identify()
      identifyEvent.set('feature flags', {
        rewardsPrice,
        rewardsBalance,
        allowPositioningOfInfoBlocks,
        newRedeemPointsValue,
        pointsToUseShouldPoll,
      })
      identifyEvent.set(
        'partnerApp',
        isEonxEnv ? Partner.Eonx : Partner.Unspecified,
      )
      amplitude.identify(identifyEvent)
    }
  }, [
    flagsReady,
    rewardsBalance,
    rewardsPrice,
    allowPositioningOfInfoBlocks,
    newRedeemPointsValue,
    pointsToUseShouldPoll,
    isEonxEnv,
  ])

  return <>{children}</>
}

export const useAmplitude = () => {
  const identifyApp = useCallback((partnerApp: Partner) => {
    const identifyEvent = new amplitude.Identify()
    identifyEvent.set('partnerApp', partnerApp)
    amplitude.identify(identifyEvent)
  }, [])

  const logEvent = useCallback(
    (
      eventInput: string | BaseEvent,
      eventProperties?: Record<string, any> | undefined,
    ) => {
      amplitude.track(eventInput, eventProperties)
    },
    [],
  )

  return { logEvent, identifyApp }
}
