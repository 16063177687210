import { CurrentUserQuery, VenueMembershipPayload } from 'gql/graphql'
import React from 'react'

import { ActivateMembershipCTA } from './ActivateMembershipCTA'
import { ManageMembershipCTA } from './ManageMembershipCTA'
import { SignUpForMembershipCTA } from './SignUpForMembershipCTA'

export const ExternalLoyaltyMembershipCTA = ({
  currentUser,
  venueId,
  venueName,
  venueSlug,
  programs,
  showManageMembershipCTA = true,
}: {
  currentUser: CurrentUserQuery['currentUser']
  venueId: string
  venueName: string
  venueSlug: string
  programs: Array<
    Pick<
      VenueMembershipPayload,
      'id' | 'name' | 'logo' | 'configuration' | 'membership'
    >
  >
  showManageMembershipCTA?: boolean
}) => {
  return (
    <>
      {programs.map((program) => {
        const isLinked = Boolean(program.membership.id)

        if (currentUser && !isLinked) {
          return (
            <ActivateMembershipCTA
              key={program.id}
              venueName={venueName}
              venueSlug={venueSlug}
              program={program}
            />
          )
        }

        if (currentUser && isLinked && showManageMembershipCTA) {
          return (
            <ManageMembershipCTA
              key={program.id}
              venueId={venueId}
              venueName={venueName}
              venueSlug={venueSlug}
              program={program}
            />
          )
        }

        if (!currentUser) {
          return (
            <SignUpForMembershipCTA
              key={program.id}
              venueName={venueName}
              venueSlug={venueSlug}
              program={program}
            />
          )
        }

        return null
      })}
    </>
  )
}
