import { RouteLink } from '@mr-yum/frontend-core/dist/services/routes'
import { isServer } from '@mr-yum/frontend-core/dist/support/env'
import { applyUtmToUrl } from '@mr-yum/frontend-core/dist/support/links'
import cn from 'classnames'
import {
  FLAG_DISABLE_REORDER_DRINKS,
  RATE_YOUR_EXPERIENCE_ENABLED,
} from 'common/constants'
import { useEonxEnvContext } from 'components/EonX/EonxEnv'
import { Text } from 'components/Skeleton/Text'
import { getReorderId } from 'components/Venue/utils/venueUtils'
import {
  HamburgerMenuAdditionalQuery,
  HamburgerMenuQuery,
  ListOpenGroupOrdersForVenueDocument,
  OrderingType,
} from 'gql/graphql'
import { useFeatureFlag } from 'hooks/useFeatureFlag'
import { useLogger } from 'hooks/useLogger'
import { isLokeOrigin } from 'hooks/useLoke'
import { EventType, useReorder } from 'hooks/useReorder'
import { routes } from 'lib/routes'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useQuery } from 'urql'

interface Props {
  venueSlug: string
  venue: HamburgerMenuQuery['guestVenue']
  venueFetching?: boolean
  currentUser: HamburgerMenuAdditionalQuery['currentUser']
  lastOrder?: HamburgerMenuAdditionalQuery['lastOrderByUserByVenueSlug']
  additionalFetching?: boolean
  orderingType: OrderingType
}

export const VenueMenu = observer(
  ({
    venueSlug,
    venue,
    venueFetching,
    orderingType,
    currentUser,
    lastOrder,
    additionalFetching,
  }: Props) => {
    const { isEonxEnv } = useEonxEnvContext()
    const intl = useIntl()
    const { logEvent } = useLogger()
    const { handleReorder, reorderFetching, reorderError } = useReorder(
      EventType.hamburgerMenu,
    )
    const { value: rateYourExperienceEnabled } = useFeatureFlag(
      RATE_YOUR_EXPERIENCE_ENABLED,
    )

    const [
      {
        data: currentUserOpenGroupOrders,
        fetching: currentUserGroupOrdersFetching,
      },
    ] = useQuery({
      query: ListOpenGroupOrdersForVenueDocument,
      pause: isServer || !currentUser || !venue?.id,
      variables: {
        venueId: venue?.id ?? '',
      },
      requestPolicy: 'cache-and-network',
    })

    const bookingLink = venue?.bookingLink

    const groupOrders = currentUserOpenGroupOrders?.listBills.items || []

    const hasGroupOrdering =
      !!venue?.startTabAvailable && !isLokeOrigin() && !isEonxEnv

    const showSurveyLink =
      venue?.surveyLink &&
      !venue.yumpingoVenueId &&
      !isEonxEnv &&
      rateYourExperienceEnabled

    const { reorderId } = getReorderId(lastOrder, venue, orderingType)

    const { value: disableReorderDrinks } = useFeatureFlag(
      FLAG_DISABLE_REORDER_DRINKS,
    )

    return (
      <ul>
        {venueFetching && (
          <li className="block px-8 py-4 transition-colors duration-300 ease-out my-body-lg">
            <Text />
          </li>
        )}

        {!additionalFetching &&
          !currentUserGroupOrdersFetching &&
          hasGroupOrdering &&
          groupOrders.length === 0 && (
            <li>
              <RouteLink route={routes.socialTabStart} pathData={{ venueSlug }}>
                <a
                  className="block px-8 py-4 text-foreground transition-colors duration-300 ease-out my-body-lg hover:bg-interactive-surface-hover"
                  aria-label={intl.formatMessage({
                    defaultMessage: 'Start group order for menu item',
                    id: '2eAgcX',
                  })}
                  onClick={() => {
                    logEvent('Click on start tab from hamburger menu')
                  }}
                >
                  <FormattedMessage
                    defaultMessage="Start a group order"
                    id="aMQu9H"
                  />
                </a>
              </RouteLink>
            </li>
          )}

        {hasGroupOrdering &&
          groupOrders.map((tab) => (
            <li key={tab.id}>
              <RouteLink
                key={tab.id}
                route={routes.socialTabManage}
                pathData={{ venueSlug, tabId: tab.id }}
              >
                <a
                  className="block px-8 py-4 text-foreground transition-colors duration-300 ease-out my-body-lg hover:bg-interactive-surface-hover"
                  aria-label={intl.formatMessage({
                    defaultMessage: 'Manage my group order menu item',
                    id: 'MvM99V',
                  })}
                  onClick={() => {
                    logEvent('Click on manage tab from hamburger menu')
                  }}
                >
                  <FormattedMessage
                    defaultMessage="Manage my group order"
                    id="w5/OUl"
                  />
                </a>
              </RouteLink>
            </li>
          ))}

        {showSurveyLink && (
          <li>
            <a
              href={venue?.surveyLink || ''}
              target="_blank"
              rel="noopener noreferrer"
              className="block px-8 py-4 text-foreground transition-colors duration-300 ease-out my-body-lg hover:bg-interactive-surface-hover"
              aria-label={intl.formatMessage({
                defaultMessage: 'Leave a review menu item',
                id: '1j10x3',
              })}
              onClick={() => {
                logEvent('Click on leave a review from hamburger menu')
              }}
            >
              <FormattedMessage defaultMessage="Leave a review" id="/+HyHJ" />
            </a>
          </li>
        )}

        {!disableReorderDrinks && reorderId && (
          <li
            className={cn({
              'cursor-wait': reorderFetching,
              'cursor-pointer': !reorderFetching,
            })}
          >
            <span
              className={cn(
                'block px-8 py-4 text-foreground transition-colors duration-300 ease-out my-body-lg hover:bg-interactive-surface-hover',
              )}
              aria-label={intl.formatMessage({
                defaultMessage: 'Reorder drinks menu item',
                id: 'MO4iLa',
              })}
              onClick={() => {
                void handleReorder(venueSlug, reorderId)
              }}
            >
              {reorderError ? (
                <FormattedMessage
                  defaultMessage="Oops! Try reordering again"
                  id="S4uYHc"
                />
              ) : reorderFetching ? (
                <FormattedMessage defaultMessage="Reordering..." id="UugRIX" />
              ) : (
                <FormattedMessage defaultMessage="Reorder drinks" id="wRaxyc" />
              )}
            </span>
          </li>
        )}

        {venue?.landingCallToActionText && venue.landingCallToActionUrl && (
          <li>
            <a
              href={applyUtmToUrl(venue.landingCallToActionUrl, {
                medium: 'landing-cta',
              })}
              target="_blank"
              rel="noopener noreferrer"
              data-testid="custom-cta"
              className="block break-words px-8 py-4 text-foreground transition-colors duration-300 ease-out my-body-lg hover:bg-interactive-surface-hover"
              aria-label={intl.formatMessage({
                defaultMessage: 'Call to action menu item',
                id: '8ZDJ7y',
              })}
              onClick={() => {
                logEvent('Click on venue custom cta from hamburger menu')
              }}
            >
              {venue.landingCallToActionText}
            </a>
          </li>
        )}

        {bookingLink && (
          <li>
            <a
              href={bookingLink}
              target="_blank"
              rel="noopener noreferrer"
              className="block px-8 py-4 text-foreground transition-colors duration-300 ease-out my-body-lg hover:bg-interactive-surface-hover"
              aria-label={intl.formatMessage({
                defaultMessage: 'Book a table menu item',
                id: 'M33oMS',
              })}
              onClick={() => {
                logEvent('Click on book a table from hamburger menu')
              }}
            >
              <FormattedMessage defaultMessage="Book a table" id="1qoX0/" />
            </a>
          </li>
        )}
      </ul>
    )
  },
)

VenueMenu.displayName = 'VenueMenu'
