import { useStripe } from '@stripe/react-stripe-js'
import { PaymentRequest, Stripe } from '@stripe/stripe-js'
import { useEonxEnvContext } from 'components/EonX/EonxEnv'
import ApplePay from 'components/Icons/ApplePay'
import CashIcon from 'components/Icons/Cash'
import ChargeToRoomIcon from 'components/Icons/ChargeToRoom'
import CreditCard from 'components/Icons/CreditCard'
import GooglePay from 'components/Icons/GooglePay'
import SnapscanIcon from 'components/Icons/Snapscan'
import UnpaidIcon from 'components/Icons/Unpaid'
import {
  useOrderingTypeContext,
  useVenueContext,
} from 'contexts/VenueOrderContext'
import {
  CustomerPaystackCardDocument,
  CustomerStripeCardDocument,
  GuestVenuePaymentProcessorType,
  OrderingType,
  PaymentFormQuery,
  PaymentProcessorType,
  PaystackCardPartsFragment,
  SnapscanPaymentQrUrlDocument,
  SnapscanPaymentQrUrlQuery,
  StripeCardPartsFragment,
} from 'gql/graphql'
import { reportErrorContext } from 'lib/bugsnag'
import filter from 'lodash/fp/filter'
import flow from 'lodash/fp/flow'
import isEmpty from 'lodash/fp/isEmpty'
import isObject from 'lodash/fp/isObject'
import values from 'lodash/fp/values'
import { useRouter } from 'next/router'
import React, { useContext, useEffect, useState } from 'react'
import { BookingDetails, CustomerStoreContext } from 'stores/CustomerStore'
import { CombinedError, useQuery } from 'urql'
import { getStripePlatformAccountCountryCodeForCurrency } from 'utils/stripe'

import { getCardLogoForBrand } from '../Stripe/utils'
import { isPaymentProcessorAvailableForOrderingType } from './utils'

export type PaymentMethod = {
  /**
   * Should be unique
   */
  key: string
  /**
   * Polymorphic, whatever the paymentMethod requires to be passed
   */
  value: string | SnapscanPaymentQrUrlQuery | null
  processorType: PaymentProcessorType
  position: number
  Icon: React.ElementType
  label: string | React.ReactNode
  tracking: string
  visible?: boolean
  fetching?: boolean
  canStartWallet?: boolean
}

export type PaymentMethodValue = Pick<
  PaymentMethod,
  'key' | 'value' | 'processorType' | 'tracking'
>

export enum QuickPaymentMethodKey {
  applePay = 'Apple Pay',
  googlePay = 'Google Pay',
}
export interface StripePaymentRequestMethod extends PaymentMethod {
  key: PaymentMethodKey.StripePaymentRequest
  label: QuickPaymentMethodKey
  tracking: QuickPaymentMethodKey
  value: null
}

export interface RegularPaymentMethod extends PaymentMethod {
  value: string | null
}

export interface SnapscanPaymentMethod extends PaymentMethod {
  value: SnapscanPaymentQrUrlQuery | null
}

export const isStripePaymentRequest = (
  paymentMethod: PaymentMethodValue,
): paymentMethod is StripePaymentRequestMethod =>
  paymentMethod.key === PaymentMethodKey.StripePaymentRequest

export const isSnapscanPaymentMethod = (
  paymentMethod: PaymentMethodValue,
): paymentMethod is SnapscanPaymentMethod =>
  paymentMethod.key === PaymentMethodKey.Snapscan

export const isRegularPaymentMethod = (
  paymentMethod: PaymentMethodValue,
): paymentMethod is RegularPaymentMethod =>
  !isSnapscanPaymentMethod(paymentMethod) &&
  !isStripePaymentRequest(paymentMethod)

interface UsePaymentMethodArgs {
  venue: NonNullable<PaymentFormQuery['guestVenue']>
  totalInCents: number
  isStartWallet?: boolean
  paymentFormData: NonNullable<PaymentFormQuery>
}

export interface UsePaymentMethodsReturnType {
  paymentRequest?: PaymentRequest | null
  paymentRequestFetching: boolean
  paymentMethods: PaymentMethod[]
  /**
   * Is any paymentMethod still fetching ?
   */
  paymentMethodsFetching: boolean
  paymentMethodsErrors: CombinedError[]
  submitError?: Error | null
}

export enum PaymentMethodKey {
  TabNew = 'tab-new',
  TabExisting = 'tab-existing',
  StripeExistingCard = 'stripe-existing',
  StripeNewCard = 'stripe-new-card',
  StripePaymentRequest = 'stripe-payment-request',
  PaystackExistingCard = 'paystack-existing',
  PaystackNewCard = 'paystack-new-card',
  PaystackPaymentRequest = 'paystack-payment-request',
  Snapscan = 'snapscan',
  Loke = 'loke',
  Afterpay = 'afterpay', // Deprecated. [SRV-3453]
  ChargeToRoom = 'charge-to-room',
  Cash = 'cash',
  Unpaid = 'unpaid',
  Eonx = 'eonx',
}

interface PaymentMethodOptions {
  paymentFormData: NonNullable<PaymentFormQuery>
  orderingType?: OrderingType
  hasWallet?: boolean
  hasStripe?: boolean
  isNewTab?: boolean
  stripeCard?: StripeCardPartsFragment | null
  fetchingStripeCard?: boolean
  hasTabsEnabled?: boolean
  paymentRequest?: PaymentRequest
  applePay?: boolean
  googlePay?: boolean
  paymentRequestFetching?: boolean
  paystackCard?: PaystackCardPartsFragment | null
  fetchingPaystackCard?: boolean
  hasPaystack?: boolean
  snapscanData?: SnapscanPaymentQrUrlQuery
  hasSnapscan?: boolean
  snapscanFetching?: boolean
  bookingDetails?: BookingDetails
  hasChargeToRoom?: boolean
  hasCash?: boolean
  unpaidLabel?: string | null
  hasUnpaid?: boolean
  isStartWallet?: boolean
  totalInCents?: number | undefined
  isEonxEnv: boolean
}

export const getPaymentMethods = (
  opts: PaymentMethodOptions,
): PaymentMethod[] => {
  const { isEonxEnv } = opts

  const paymentMethods: PaymentMethod[] = [
    {
      key: PaymentMethodKey.StripeExistingCard,
      value: opts.stripeCard?.paymentMethodId ?? null,
      processorType: PaymentProcessorType.Stripe,
      position: 3,
      label: opts.stripeCard ? (
        <>
          {opts.stripeCard.brand.toUpperCase()} &bull;&bull;&bull;&bull;{' '}
          {opts.stripeCard.last4} {opts.stripeCard.expiry}
        </>
      ) : null,
      tracking: 'Existing Stripe card',
      Icon: () => getCardLogoForBrand(opts.stripeCard?.brand),
      visible:
        opts.hasStripe &&
        (opts.fetchingStripeCard || !!opts.stripeCard) &&
        !isEonxEnv,
      fetching: opts.fetchingStripeCard || !opts.stripeCard,
      canStartWallet: true,
    },
    {
      key: PaymentMethodKey.StripePaymentRequest,
      value: null,
      processorType: PaymentProcessorType.Stripe,
      position: 2,
      label: opts.applePay
        ? QuickPaymentMethodKey.applePay
        : QuickPaymentMethodKey.googlePay,
      tracking: opts.applePay
        ? QuickPaymentMethodKey.applePay
        : QuickPaymentMethodKey.googlePay,
      Icon: opts.applePay ? ApplePay : GooglePay,
      visible:
        opts.hasStripe &&
        (opts.paymentRequestFetching || !!opts.paymentRequest) &&
        !isEonxEnv,
      fetching: opts.paymentRequestFetching,
      canStartWallet: true,
    },
    {
      key: PaymentMethodKey.StripeNewCard,
      value: null,
      processorType: PaymentProcessorType.Stripe,
      position: 4,
      label: 'New credit/debit card',
      tracking: 'New Stripe card',
      Icon: CreditCard,
      visible: opts.hasStripe && !isEonxEnv,
      canStartWallet: true,
    },
    {
      key: PaymentMethodKey.PaystackExistingCard,
      value: opts.paystackCard?.paymentMethodId ?? null,
      processorType: PaymentProcessorType.Paystack,
      position: 2,
      label: opts.paystackCard ? (
        <>
          {opts.paystackCard.brand.toUpperCase()} &bull;&bull;&bull;&bull;{' '}
          {opts.paystackCard.last4} {opts.paystackCard.expiry}
        </>
      ) : null,
      tracking: 'Existing Paystack card',
      Icon: () => getCardLogoForBrand(opts.paystackCard?.brand),
      visible:
        opts.hasPaystack &&
        (opts.fetchingPaystackCard || !!opts.paystackCard) &&
        !isEonxEnv,
      fetching: opts.fetchingPaystackCard || !opts.paystackCard,
    },
    {
      key: PaymentMethodKey.PaystackNewCard,
      value: null,
      processorType: PaymentProcessorType.Paystack,
      position: 4,
      label: 'New credit/debit card',
      tracking: 'New Paystack card',
      Icon: CreditCard,
      visible: opts.hasPaystack && !isEonxEnv,
    },
    {
      key: PaymentMethodKey.Snapscan,
      value: opts.snapscanData ?? null,
      processorType: PaymentProcessorType.Snapscan,
      position: 7,
      label: 'Snapscan',
      tracking: 'Snapscan',
      Icon: SnapscanIcon,
      visible: !opts.isNewTab && opts.hasSnapscan && !isEonxEnv,
      fetching: opts.snapscanFetching || !opts.snapscanData,
    },
    {
      key: PaymentMethodKey.ChargeToRoom,
      position: opts.bookingDetails ? 3 : 5,
      processorType: PaymentProcessorType.ChargeToRoom,
      label: 'Charge to my room',
      tracking: 'Charge to my room',
      Icon: ChargeToRoomIcon,
      visible: !opts.isNewTab && opts.hasChargeToRoom && !isEonxEnv,
      value: null,
    },
    {
      key: PaymentMethodKey.Cash,
      position: 100,
      processorType: PaymentProcessorType.Cash,
      label: 'Cash',
      tracking: 'Cash',
      Icon: CashIcon,
      visible: !opts.isNewTab && opts.hasCash && !isEonxEnv,
      value: null,
    },
    {
      key: PaymentMethodKey.Unpaid,
      position: 98,
      processorType: PaymentProcessorType.Unpaid,
      label: opts.unpaidLabel || 'Pay later',
      tracking: 'Pay later',
      Icon: UnpaidIcon,
      visible: !opts.isNewTab && opts.hasUnpaid && !isEonxEnv,
      value: null,
    },
  ]

  return paymentMethods
    .flat()
    .map((item) => ({
      ...item,
      visible:
        isPaymentProcessorAvailableForOrderingType(
          item.processorType,
          opts.orderingType,
        ) && item.visible,
    }))
    .filter((paymentMethod) => paymentMethod.visible === true)
    .filter(({ canStartWallet }) =>
      opts.isStartWallet ? !!canStartWallet : true,
    )
    .sort((a, b) => a.position - b.position)
}

/**
 * Return available payment methods
 */
export const usePaymentFormMethods = ({
  venue,
  totalInCents,
  isStartWallet = false,
  paymentFormData,
}: UsePaymentMethodArgs): UsePaymentMethodsReturnType => {
  const { venueSlug } = useVenueContext()
  const { orderingType } = useOrderingTypeContext()
  const paymentProcessorTypes = venue.paymentProcessor?.types || []
  const customerId = paymentFormData.currentUser?.customerId

  const { getBookingDetailsForVenueSlug } = useContext(CustomerStoreContext)
  const bookingDetails = getBookingDetailsForVenueSlug(venueSlug)
  /**
   * Are we opening a new tab ?
   */
  const { query } = useRouter()
  const paymentState = query.state || []
  const isNewTab = paymentState.includes('tab')

  /**
   * Processor Types Shortcuts
   */
  const hasTabsEnabled = !!venue?.tabsEnabled
  const hasChargeToRoom = paymentProcessorTypes.includes(
    GuestVenuePaymentProcessorType.ChargeToRoom,
  )
  const hasStripe = paymentProcessorTypes.includes(
    GuestVenuePaymentProcessorType.Stripe,
  )
  const hasPaystack = paymentProcessorTypes.includes(
    GuestVenuePaymentProcessorType.Paystack,
  )
  const hasWallet = paymentProcessorTypes.includes(
    GuestVenuePaymentProcessorType.Wallet,
  )
  const hasSnapscan = paymentProcessorTypes.includes(
    GuestVenuePaymentProcessorType.Snapscan,
  )
  const hasCash = paymentProcessorTypes.includes(
    GuestVenuePaymentProcessorType.Cash,
  )
  const hasUnpaid = paymentProcessorTypes.includes(
    GuestVenuePaymentProcessorType.Unpaid,
  )
  const unpaidLabel = venue?.paymentProcessor?.unpaidProcessorTypeLabel

  /**
   * Stripe Hook
   */
  const stripe = useStripe()

  /**
   * Existing customer card
   * Won't load if Stripe is not enabled
   */
  const [
    {
      data: stripeCardData,
      fetching: fetchingStripeCard,
      error: stripeCardError,
    },
  ] = useQuery({
    query: CustomerStripeCardDocument,
    variables: { customerId: customerId!, currency: String(venue?.currency) },
    pause: !hasStripe || !customerId || !venue?.currency,
  })
  const stripeCard = stripeCardData?.stripeCard

  /**
   * Existing customer card
   * Won't load if Paystack is not enabled
   */
  const [
    {
      data: paystackCardData,
      fetching: fetchingPaystackCard,
      error: paystackCardError,
    },
  ] = useQuery({
    query: CustomerPaystackCardDocument,
    variables: { customerId: customerId!, currency: String(venue?.currency) },
    pause: !hasPaystack || !customerId || !venue?.currency,
  })
  const { isEonxEnv } = useEonxEnvContext()
  const paystackCard = paystackCardData?.paystackCard

  /**
   * Apple / Google Pay
   * Won't load if Stripe is not enabled
   */
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest>()
  const [paymentRequestFetching, setPaymentRequestFetching] = useState(false)
  const [applePay, setApplePay] = useState(false)
  const [googlePay, setGooglePay] = useState(false)
  const currency = venue.currency

  // we need this to only run once on page load once we have a venue, totalInCents, stripe and venue
  useEffect(() => {
    async function checkPaymentRequestAvailable(
      stripe: Stripe,
      totalInCents: number,
      currency: string,
    ) {
      try {
        setPaymentRequestFetching(true)
        const pr = stripe.paymentRequest({
          country: getStripePlatformAccountCountryCodeForCurrency(currency),
          currency: currency.toLowerCase(),
          total: {
            label: 'me&u',
            amount: totalInCents,
          },
        })

        const result = await pr.canMakePayment()
        if (!result) {
          return
        }
        if (result.applePay) setApplePay(true)
        if (result.googlePay) setGooglePay(true)
        if (result.applePay || result.googlePay) {
          setPaymentRequest(pr)
        }
      } catch (error) {
        reportErrorContext(error, 'stripe')
      } finally {
        setPaymentRequestFetching(false)
      }
    }

    if (hasStripe && stripe && totalInCents && currency) {
      void checkPaymentRequestAvailable(stripe, totalInCents, currency)
    }
  }, [hasStripe, stripe, totalInCents, currency])
  /**
   * Snapscan
   * Will only load if Snapscan is an option
   */
  const [
    { data: snapscanData, fetching: snapscanFetching, error: snapscanError },
  ] = useQuery({
    query: SnapscanPaymentQrUrlDocument,
    requestPolicy: 'network-only',
    variables: {
      input: {
        venueSlug,
        type: orderingType,
        paymentProcessorType: PaymentProcessorType.Snapscan,
      },
    },
    pause: !hasSnapscan,
  })

  const paymentMethods = getPaymentMethods({
    paymentFormData,
    orderingType,
    hasTabsEnabled,
    isNewTab,
    hasWallet,
    stripeCard,
    hasStripe,
    fetchingStripeCard,
    paymentRequest,
    applePay,
    googlePay,
    paymentRequestFetching,
    paystackCard,
    fetchingPaystackCard,
    hasPaystack,
    snapscanData,
    hasSnapscan,
    snapscanFetching,
    bookingDetails,
    hasChargeToRoom,
    hasCash,
    hasUnpaid,
    unpaidLabel,
    isStartWallet,
    totalInCents,
    isEonxEnv,
  })

  const paymentMethodsFetching =
    isEmpty(paymentMethods) ||
    !flow(filter({ fetching: true }), isEmpty)(paymentMethods)

  return {
    paymentRequest,
    paymentRequestFetching,
    paymentMethods,
    paymentMethodsFetching,
    paymentMethodsErrors: flow(
      values,
      filter(isObject),
    )({
      customerCardError: stripeCardError,
      paystackCardError,
      snapscanError,
    }) as CombinedError[],
  }
}
