import {
  CreateOrderMutation,
  OrderingType,
  PaymentProcessorType,
} from 'gql/graphql'
import { OperationResult } from 'urql'

import { PaymentSuccessData } from './usePaymentFormSubmit'

const POST_PAID_PROCESSOR_TYPES = [
  PaymentProcessorType.ChargeToRoom,
  PaymentProcessorType.Cash,
  PaymentProcessorType.Unpaid,
]

const ALLOWED_ORDERING_TYPES_FOR_POST_PAID = [
  OrderingType.DineIn,
  OrderingType.Counter,
  OrderingType.InVenue,
]

/**
 * Wallet and post paid processor types are only allowed for some ordering types
 */
export const isPaymentProcessorAvailableForOrderingType = (
  paymentProcessorType: PaymentProcessorType,
  orderingType?: OrderingType,
) => {
  // Show post paid only on allowed ordering types
  if (
    orderingType &&
    POST_PAID_PROCESSOR_TYPES.includes(paymentProcessorType)
  ) {
    return ALLOWED_ORDERING_TYPES_FOR_POST_PAID.includes(orderingType)
  }

  return true
}

export const isCreateOrderMutation = (
  paymentSuccess: PaymentSuccessData,
): paymentSuccess is NonNullable<
  OperationResult<CreateOrderMutation>['data']
> => {
  return paymentSuccess ? 'createOrder' in paymentSuccess : false
}
