import { GuestVenue, Maybe } from 'gql/graphql'
import { useRouter } from 'next/router'
import Script from 'next/script'
import React from 'react'

interface Props {
  googleTagManagerId?: string
}

export const GTMScript = ({ googleTagManagerId }: Props) => {
  if (!googleTagManagerId) return null
  return (
    <Script
      id="googleTagManager"
      key={`script-${googleTagManagerId}`}
      dangerouslySetInnerHTML={{
        __html: `!(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','${googleTagManagerId}');`,
      }}
    />
  )
}

export const GTMIframe = ({ googleTagManagerId }: Props) => {
  if (!googleTagManagerId) return null
  return (
    <noscript>
      <iframe
        key={`iframe-${googleTagManagerId}`}
        src={`https://www.googletagmanager.com/ns.html?id=${googleTagManagerId}`}
        height="0"
        width="0"
        style={{ display: 'none', visibility: 'hidden' }}
      />
    </noscript>
  )
}

export const GoogleTagManager = ({
  guestVenue,
}: {
  guestVenue?: Maybe<Pick<GuestVenue, 'googleTagManagerId'>>
}) => {
  const router = useRouter()

  let googleTagManagerId = guestVenue?.googleTagManagerId

  if (router.asPath.match(/^\/discover\/syd/)) {
    googleTagManagerId = 'GTM-M85ZCBW'
  } else if (router.asPath.match(/^\/discover\/perthairport/)) {
    googleTagManagerId = 'GTM-KXCBGDS'
  }

  return GTMScript({ googleTagManagerId: googleTagManagerId! })
}

export const GoogleTagManagerIframe = ({
  guestVenue,
}: {
  guestVenue?: Maybe<Pick<GuestVenue, 'googleTagManagerId'>>
}) => {
  const router = useRouter()

  let googleTagManagerId = guestVenue?.googleTagManagerId

  if (router.asPath.match(/^\/discover\/syd/)) {
    googleTagManagerId = 'GTM-M85ZCBW'
  } else if (router.asPath.match(/^\/discover\/perthairport/)) {
    googleTagManagerId = 'GTM-KXCBGDS'
  }

  return <GTMIframe googleTagManagerId={googleTagManagerId!} />
}
