import { isServer } from '@mr-yum/frontend-core/dist/support/env'
import { RequestPolicy } from '@urql/core'
import {
  AddOrUpdateTableNumberOnCartDocument,
  CartLandingDocument,
  CartMenuDocument,
  CartMenuItemDocument,
  CartPageDocument,
  CartPaymentDocument,
  OrderingType,
} from 'gql/graphql'
import { CartDefaults, getCleanPath, useCartDefaults } from 'lib/utils'
import Router, { useRouter } from 'next/router'
import { useCallback, useEffect, useRef } from 'react'
import { useMutation, useQuery } from 'urql'

import { useLogger } from './useLogger'

interface Props {
  venueSlug: string
  orderingType: OrderingType
  revalidate?: boolean
  checkMembership?: boolean
  requestPolicy?: RequestPolicy | undefined
  pause?: boolean
}

/*
  This results in querying getCart without a table number/table area after initial load. It doesn't
  seem to be an issue as the API ignores table number/table area after the first query which includes it. This
  must be guest session/cart token kicking in.
*/
export function useCartWrapper({
  defaults,
  venueSlug,
  orderingType,
  tableNumber,
  fetching,
}: {
  defaults: CartDefaults
  venueSlug: string
  orderingType: OrderingType
  tableNumber?: string | null
  fetching: boolean
}) {
  const router = useRouter()
  const firedUpdateRef = useRef(false)
  const fetchingTable = useRef(true)
  const { logEvent } = useLogger()

  const [{ data: cartData }, addOrUpdateTableNumberOnCart] = useMutation(
    AddOrUpdateTableNumberOnCartDocument,
  )

  const replaceCleanPath = useCallback(async () => {
    await Router.replace(
      getCleanPath(router.pathname, {
        sheet: router.query?.sheet,
      }),
      router.asPath
        ? getCleanPath(router.asPath, {
            sheet: router.query?.sheet,
          })
        : undefined,
      { shallow: true },
    )

    fetchingTable.current = false
  }, [router])

  const updateTableNumber = useCallback(async () => {
    await addOrUpdateTableNumberOnCart({
      input: {
        venueSlug,
        orderingType,
        tableArea: defaults.tableArea,
        tableNumber: defaults.tableNumber || '',
      },
    })

    await replaceCleanPath()

    logEvent('Updated embedded table number', {
      venueSlug,
      orderingType,
      tableArea: defaults.tableArea,
      tableNumber: defaults.tableNumber,
    })
  }, [
    addOrUpdateTableNumberOnCart,
    replaceCleanPath,
    venueSlug,
    orderingType,
    defaults,
    logEvent,
  ])

  useEffect(() => {
    // Early return if not ready to react
    if (
      Object.keys(defaults).length === 0 ||
      fetching ||
      firedUpdateRef.current
    ) {
      return
    }

    // Early return for menu ordering type
    if (orderingType === OrderingType.Menu) {
      firedUpdateRef.current = true
      fetchingTable.current = false
      return
    }

    // if it needs updating
    if (
      defaults.tableNumber &&
      (tableNumber || '').toUpperCase() !== defaults.tableNumber
    ) {
      void updateTableNumber()
    } else {
      // if not, just clean the path
      void replaceCleanPath()
    }

    firedUpdateRef.current = true
  }, [
    fetching,
    tableNumber,
    orderingType,
    firedUpdateRef,
    replaceCleanPath,
    defaults,
    updateTableNumber,
  ])

  useEffect(() => {
    if (!defaults.tableNumber) {
      fetchingTable.current = false
    }
  }, [defaults.tableNumber])

  return {
    cart: cartData?.addOrUpdateTableNumberOnCart,
    fetchingTable: fetchingTable.current,
  }
}

export function useCartPage({ requestPolicy, ...props }: Props) {
  const defaults = useCartDefaults()

  const [{ data, stale, fetching, error }, refetch] = useQuery({
    query: CartPageDocument,
    variables: {
      ...props,
      ...defaults,
    },
    pause: isServer || props.orderingType === OrderingType.Menu || props.pause,
    requestPolicy,
  })

  const cart = data?.getCart

  useCartWrapper({
    defaults,
    venueSlug: props.venueSlug,
    orderingType: props.orderingType,
    tableNumber: cart?.tableNumber,
    fetching,
  })

  return {
    cart,
    stale,
    fetching,
    error,
    refetch,
  }
}

export function useCartPayment({ requestPolicy, pause, ...props }: Props) {
  const defaults = useCartDefaults()

  const [{ data, stale, fetching, error }, refetch] = useQuery({
    query: CartPaymentDocument,
    variables: {
      ...props,
      ...defaults,
    },
    pause: pause || isServer || props.orderingType === OrderingType.Menu,
    requestPolicy,
  })

  const cart = data?.getCart

  useCartWrapper({
    defaults,
    venueSlug: props.venueSlug,
    orderingType: props.orderingType,
    tableNumber: cart?.tableNumber,
    fetching,
  })

  return {
    cart,
    stale,
    fetching,
    error,
    refetch,
  }
}

export function useCartMenu({ requestPolicy, pause, ...props }: Props) {
  const defaults = useCartDefaults()

  const [{ data, stale, fetching, error }, refetch] = useQuery({
    query: CartMenuDocument,
    variables: {
      ...props,
      ...defaults,
    },
    pause: isServer || props.orderingType === OrderingType.Menu || pause,
    requestPolicy,
  })

  const cart = data?.getCart

  useCartWrapper({
    defaults,
    venueSlug: props.venueSlug,
    orderingType: props.orderingType,
    tableNumber: cart?.tableNumber,
    fetching,
  })

  return {
    cart,
    stale,
    fetching,
    error,
    refetch,
  }
}

export function useCartMenuItem({ requestPolicy, pause, ...props }: Props) {
  const defaults = useCartDefaults()

  const [{ data, stale, fetching, error }, refetch] = useQuery({
    query: CartMenuItemDocument,
    variables: {
      ...props,
      ...defaults,
    },
    pause: isServer || props.orderingType === OrderingType.Menu || pause,
    requestPolicy,
  })

  const cart = data?.getCart

  useCartWrapper({
    defaults,
    venueSlug: props.venueSlug,
    orderingType: props.orderingType,
    tableNumber: cart?.tableNumber,
    fetching,
  })

  return {
    cart,
    stale,
    fetching,
    error,
    refetch,
  }
}

export function useCartLanding({ requestPolicy, pause, ...props }: Props) {
  const [{ data, stale, fetching, error }, refetch] = useQuery({
    query: CartLandingDocument,
    variables: {
      ...props,
    },
    pause: isServer || props.orderingType === OrderingType.Menu || pause,
    requestPolicy,
  })

  const cart = data?.getCart

  return {
    cart,
    stale,
    fetching,
    error,
    refetch,
  }
}
