/**
 * Simple region to default currency lookup. Defaults to AUD.
 */
export const getCurrencyForRegion = (region?: string): string => {
  if (region === 'eu1') {
    return 'GBP'
  }

  if (region === 'us1') {
    return 'USD'
  }

  return 'AUD'
}
