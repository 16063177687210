import {
  getSessionStorage,
  isServer,
  setSessionStorage,
} from '@mr-yum/frontend-core/dist/support/env'
import { useFlag } from '@unleash/proxy-client-react'
import { FLAG_REWARD_ENABLE_AURA_OAUTH } from 'common/constants'
import {
  FetchAuraMembershipDocument,
  FetchAuraOAuthConfigDocument,
  LoyaltyMembershipDocument,
} from 'gql/graphql'
import Cookies from 'js-cookie'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { useQuery } from 'urql'

export const AuraMemberShipCookie = 'aura-membership-id'
const AuraSessionStoragePrefix = 'aura-oauth-'
type AuraSessionStorageKey = 'venue-id' | 'oauth-url' | 'program-name'

const hydrate = (venueSlug: string, key: AuraSessionStorageKey) =>
  getSessionStorage(AuraSessionStoragePrefix + venueSlug + '-' + key)

interface Props {
  venueSlug: string
}

export const useAuraOAuth = ({ venueSlug }: Props) => {
  const { query, push } = useRouter()
  const isAuraOAuthEnabled = useFlag(FLAG_REWARD_ENABLE_AURA_OAUTH)
  const [venueId, setVenueId] = useState<string | null>(
    hydrate(venueSlug, 'venue-id'),
  )
  const [OAuthURL, setOAuthURL] = useState<string | null>(
    hydrate(venueSlug, 'oauth-url'),
  )
  const [programName, setProgramName] = useState<string | null>(
    hydrate(venueSlug, 'program-name'),
  )
  const [code, setCode] = useState<string | null>(null)
  const [auraMemberId, setAuraMemberId] = useState<string | null>(() =>
    !isAuraOAuthEnabled || isServer
      ? null
      : Cookies.get(AuraMemberShipCookie + '-' + venueSlug) || null,
  )

  const [
    {
      data: membershipData,
      fetching: fetchingMembership,
      error: membershipError,
    },
  ] = useQuery({
    query: LoyaltyMembershipDocument,
    variables: { venueSlug },
    pause: !isAuraOAuthEnabled || isServer || !!auraMemberId || !!venueId,
    requestPolicy: 'network-only',
  })

  useEffect(() => {
    if (!membershipData?.guestVenue?.id) return
    setVenueId(membershipData?.guestVenue?.id)
    setSessionStorage(
      AuraSessionStoragePrefix + venueSlug + '-' + 'venue-id',
      membershipData?.guestVenue?.id,
    )
  }, [membershipData, venueSlug])

  const [{ data: configData, fetching: fetchingConfig, error: configError }] =
    useQuery({
      query: FetchAuraOAuthConfigDocument,
      variables: { venueId: venueId || '' },
      pause:
        !isAuraOAuthEnabled ||
        isServer ||
        !venueId ||
        !!auraMemberId ||
        !!programName ||
        !!OAuthURL,
      requestPolicy: 'network-only',
    })

  useEffect(() => {
    if (!configData?.fetchAuraOAuthConfig) return

    setOAuthURL(configData?.fetchAuraOAuthConfig.url)
    setSessionStorage(
      AuraSessionStoragePrefix + venueSlug + '-' + 'oauth-url',
      configData?.fetchAuraOAuthConfig.url,
    )

    setProgramName(configData?.fetchAuraOAuthConfig.programName)
    setSessionStorage(
      AuraSessionStoragePrefix + venueSlug + '-' + 'program-name',
      configData?.fetchAuraOAuthConfig.programName,
    )
  }, [configData?.fetchAuraOAuthConfig, venueSlug])

  const [
    {
      data: auraMembershipData,
      fetching: fetchingAuraMembership,
      error: auraMembershipError,
    },
  ] = useQuery({
    query: FetchAuraMembershipDocument,
    variables: {
      venueId: venueId || '',
      code: code || '',
    },
    pause:
      !isAuraOAuthEnabled || isServer || !venueId || !code || !!auraMemberId,
    requestPolicy: 'network-only',
  })

  useEffect(() => {
    if (!auraMembershipData?.fetchAuraMembership) return
    setAuraMemberId(auraMembershipData?.fetchAuraMembership.id)
    Cookies.set(
      AuraMemberShipCookie + '-' + venueSlug,
      auraMembershipData?.fetchAuraMembership.id,
    )
  }, [auraMemberId, auraMembershipData?.fetchAuraMembership, venueSlug])

  useEffect(() => {
    if (!isAuraOAuthEnabled || auraMemberId) return
    const { code, ...rest } = query
    if (code) {
      setCode(code as string)
      void push({ query: { ...rest } }, undefined, { shallow: true })
    }
  }, [auraMemberId, code, isAuraOAuthEnabled, push, query, query.code])

  const logout = () => {
    setAuraMemberId(null)
    setCode(null)
    Cookies.remove(AuraMemberShipCookie + '-' + venueSlug)
  }

  return {
    isEnabled: isAuraOAuthEnabled,
    isLoggedIn: !!auraMemberId,
    url: OAuthURL,
    programName: programName,
    auraMemberId: auraMemberId,
    fetching: fetchingMembership || fetchingConfig || fetchingAuraMembership,
    error: membershipError || configError || auraMembershipError,
    logout: logout,
  }
}
