import { Button } from '@mr-yum/frontend-ui'
import { CartValidator } from 'components/Cart/validation'
import { Address } from 'components/Ordering/Address'
import { OrderingWindow } from 'components/Ordering/OrderingWindow'
import { Text } from 'components/Skeleton/Text'
import { TableNumberLabel } from 'components/TableNumber/TableNumberLabel'
import {
  getSelectTextFromSheet,
  useGetNextSheet,
  useSheetToggle,
} from 'components/Venue/utils/sheetUtils'
import { isToday, parseISO } from 'date-fns'
import {
  GuestVenue,
  OrderingType,
  VenueOrderingWindowsDocument,
} from 'gql/graphql'
import { OrderingTypeSlug } from 'lib/routes'
import React, { useCallback } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useQuery } from 'urql'

import { OrderingTypeVenueValidator } from '../utils/venueUtils'

interface Props {
  venueSlug: string
  venue: Pick<GuestVenue, 'skipTableNumberModal'> & OrderingTypeVenueValidator
  orderingTypeSlug: OrderingTypeSlug
  orderingType: OrderingType
  cart?: CartValidator | null
  tableShortText?: string
  tablePromptText?: string
  isLoading: boolean
  isTableTurnedOff?: boolean
}

export const ConfigureOrderingType = ({
  orderingType,
  cart,
  tableShortText,
  tablePromptText,
  isLoading,
  venueSlug,
  venue,
  isTableTurnedOff,
}: Props) => {
  const intl = useIntl()
  const sheetToggle = useSheetToggle()

  const [{ data: venueOrderingWindowsData }] = useQuery({
    query: VenueOrderingWindowsDocument,
    variables: { venueSlug, orderingType },
  })

  const firstOrderingWindow =
    venueOrderingWindowsData?.venue?.orderingTypeSettingsV2?.orderingWindow?.availableOrderingWindows.find(
      (availableOrderingWindow) => availableOrderingWindow.isPast === false,
    )

  const today = firstOrderingWindow?.orderingWindowStartDate
    ? isToday(parseISO(firstOrderingWindow?.orderingWindowStartDate))
    : false

  const asapSettings =
    venueOrderingWindowsData?.venue?.orderingTypeSettingsV2?.orderingWindow
      ?.asapWindowEnabled

  const tableNumber = cart?.tableNumber
  const location = cart?.currentLocation
  const startDate = cart?.orderingWindowStartDate

  const { nextSheet, addPaths, editPaths } = useGetNextSheet({
    cart,
    asapEnabled: asapSettings,
    venue,
  })

  const handleOnClick = useCallback(() => {
    void sheetToggle({ sheet: nextSheet })
  }, [nextSheet, sheetToggle])

  if (isLoading || !cart) {
    return (
      <div className="flex min-h-[58px] items-center px-4 py-3 md:pr-8 lg:pr-12">
        <Text chars={12} />
      </div>
    )
  }

  if (addPaths.length === 0 && editPaths.length === 0) {
    return null
  }

  return (
    <div className="flex w-full flex-col px-4 py-3 md:w-auto md:pr-8 lg:pr-12">
      <div className="flex items-center justify-between">
        <div className="mr-4 my-body-sm">
          {tableNumber && !addPaths.includes('table-number') && (
            <TableNumberLabel
              shortText={tableShortText}
              number={cart?.tableNumber}
              area={cart?.tableArea}
            />
          )}

          {location && <Address location={location} />}

          {startDate && (
            <OrderingWindow
              orderingType={orderingType}
              startDate={startDate}
              endDate={cart?.orderingWindowEndDate}
            />
          )}

          {addPaths.length > 0 &&
            !startDate &&
            !asapSettings &&
            getSelectTextFromSheet(nextSheet ?? null, tablePromptText, intl)}

          {!startDate &&
            asapSettings &&
            !today &&
            getSelectTextFromSheet(nextSheet ?? null, tablePromptText, intl)}

          {asapSettings && !startDate && today && (
            <div data-testid="asap-text">
              <FormattedMessage defaultMessage="ASAP" id="K5T4eC" />
            </div>
          )}

          {addPaths.length > 0 &&
            startDate &&
            getSelectTextFromSheet(nextSheet ?? null, tablePromptText, intl)}
        </div>

        {!isTableTurnedOff && (
          <Button
            type="button"
            variant="secondary"
            size="sm"
            // disabled={true}
            // TODO this aria-label should be dynamic based on the next sheet path..
            aria-label={intl.formatMessage({
              defaultMessage: 'Configure ordering type',
              id: 'R0MNO0',
            })}
            onClick={handleOnClick}
          >
            {addPaths.length === 0 ? (
              <FormattedMessage defaultMessage="Change" id="BY343C" />
            ) : (
              <FormattedMessage defaultMessage="Select" id="kQAf2d" />
            )}
          </Button>
        )}
      </div>
    </div>
  )
}
