import { NotFound } from 'components/Common/NotFound'
import { useVenueContext } from 'contexts/VenueOrderContext'
import { VenueLandingDocument } from 'gql/graphql'
import React, { ReactNode } from 'react'
import { useIntl } from 'react-intl'
import { useQuery } from 'urql'

import { VenuePageLoader } from './VenuePageLoader'

interface Props {
  children: ReactNode
}
export const VenueLanding = ({ children }: Props) => {
  const intl = useIntl()
  const { venueSlug } = useVenueContext()

  const [{ data, error, fetching }] = useQuery({
    query: VenueLandingDocument,
    variables: {
      venueSlug,
    },
  })

  if (fetching) {
    return <VenuePageLoader categoriesCount={6} />
  }

  if (error || !data?.guestVenue) {
    return (
      <NotFound
        message={intl.formatMessage(
          {
            defaultMessage: 'Venue {venueSlug} not found',
            id: '2R567s',
          },
          { venueSlug },
        )}
        error={error}
      />
    )
  }

  return <>{children}</>
}
