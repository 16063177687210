import { CenterSpinner } from '@mr-yum/frontend-ui'
import {
  useOrderingTypeContext,
  useVenueContext,
} from 'contexts/VenueOrderContext'
import { VenueTableNumberDocument } from 'gql/graphql'
import React from 'react'
import { useQuery } from 'urql'

import { TableNumberForm } from './TableNumberForm'
import { TableNumberProps } from './types'

export const TableNumberController = ({ ...rest }: TableNumberProps) => {
  const { orderingType } = useOrderingTypeContext()
  const { venueSlug } = useVenueContext()
  const [{ data, stale }] = useQuery({
    query: VenueTableNumberDocument,
    variables: {
      venueSlug,
      orderingType,
    },
  })
  const venue = data?.guestVenue

  return stale || !venue ? (
    <CenterSpinner />
  ) : (
    <TableNumberForm venue={venue} {...rest} />
  )
}
