import {
  Button,
  ChevronLeftIcon,
  ChevronRightIcon,
  IconButton,
} from '@mr-yum/frontend-ui'
import { Text } from 'components/Skeleton/Text'
import React from 'react'
import { FormattedMessage } from 'react-intl'

export const OrderingWindowsFormLoader = () => {
  return (
    <>
      <div className="divide-y">
        <div className="flex justify-between px-4 py-3">
          <IconButton aria-label="Previous day" disabled>
            <ChevronLeftIcon />
          </IconButton>

          <div className="px-4 text-center" aria-label="Current day">
            <div className="mb-1 my-label-md">
              <Text chars={12} />
            </div>
            <div className="my-body-sm">
              <Text chars={12} />
            </div>
          </div>

          <IconButton aria-label="Next day" disabled>
            <ChevronRightIcon />
          </IconButton>
        </div>

        <div className="relative max-w-[524px]">
          <div className="relative mx-auto grid max-h-[350px] w-full grid-cols-2 gap-3 overflow-y-auto px-4 pb-8 pt-3 sm:grid-cols-3">
            {Array.from({ length: 18 }, (_, i) => (
              <Button
                type="button"
                variant="outline"
                disabled={true}
                key={i}
                className="animate-pulse"
                fullWidth
              >
                <div className="flex min-h-[36px] flex-col items-center justify-center" />
              </Button>
            ))}
          </div>

          <div className="pointer-events-none absolute bottom-0 left-0 w-full px-4">
            <div className="h-8 w-full bg-gradient-to-t from-white to-white/0"></div>
          </div>
        </div>
      </div>

      <div className="px-4 pb-4 pt-2">
        <Button type="button" fullWidth size="lg" isLoading>
          <FormattedMessage id="N2IrpM" defaultMessage="Confirm" />
        </Button>
      </div>
    </>
  )
}
