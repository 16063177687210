import {
  TableNumberValidation,
  VenueFieldRequirement,
  VenueTableNumberQuery,
} from 'gql/graphql'
import { IntlShape } from 'react-intl'
import * as Yup from 'yup'

export const DEFAULT_MIN_TABLE_NUMBER = 0
export const DEFAULT_MAX_TABLE_NUMBER = 9999

export function getValidationSchema(
  {
    tableText = 'Table Number',
    tableNumberValidation,
    minTableNumber,
    maxTableNumber,
    hasTableAreas,
    tableNumberRequirement,
  }: {
    tableText?: string
    tableNumberValidation: TableNumberValidation
    minTableNumber: number
    maxTableNumber: number
    hasTableAreas: boolean
    tableNumberRequirement: VenueFieldRequirement
  },
  intl: IntlShape,
) {
  return Yup.object().shape({
    tableNumber: (function () {
      switch (tableNumberValidation) {
        default:
        case 'ANY':
          return tableNumberRequirement === VenueFieldRequirement.Required
            ? Yup.string()
                .nullable()
                .required(
                  intl.formatMessage(
                    { defaultMessage: '{text} is required', id: 't31nZV' },
                    { text: tableText },
                  ),
                )
            : Yup.string().nullable()
        case 'NUMBER':
          switch (tableNumberRequirement) {
            default:
            case VenueFieldRequirement.Hidden:
              return Yup.number()
                .nullable()
                .transform((value) => (isNaN(value) ? 0 : value))

            case VenueFieldRequirement.Optional:
              return Yup.number()
                .nullable()
                .integer()
                .transform((value) => (isNaN(value) ? null : value))
                .min(
                  minTableNumber,
                  intl.formatMessage(
                    {
                      defaultMessage:
                        'Please use a {text} between {minTableNumber} and {maxTableNumber}',
                      id: 'p7bTx6',
                    },
                    {
                      text: tableText.toLowerCase(),
                      minTableNumber: minTableNumber,
                      maxTableNumber: maxTableNumber,
                    },
                  ),
                )
                .max(
                  maxTableNumber,
                  intl.formatMessage(
                    {
                      defaultMessage:
                        'Please use a {text} between {minTableNumber} and {maxTableNumber}',
                      id: 'p7bTx6',
                    },
                    {
                      text: tableText.toLowerCase(),
                      minTableNumber: minTableNumber,
                      maxTableNumber: maxTableNumber,
                    },
                  ),
                )

            case VenueFieldRequirement.Required:
              return Yup.number()
                .nullable()
                .integer()
                .transform((value) => (isNaN(value) ? null : value))
                .required(
                  intl.formatMessage(
                    { defaultMessage: '{text} is required', id: 't31nZV' },
                    { text: tableText },
                  ),
                )
                .min(
                  minTableNumber,
                  intl.formatMessage(
                    {
                      defaultMessage:
                        'Please use a {text} between {minTableNumber} and {maxTableNumber}',
                      id: 'p7bTx6',
                    },
                    {
                      text: tableText.toLowerCase(),
                      minTableNumber: minTableNumber,
                      maxTableNumber: maxTableNumber,
                    },
                  ),
                )
                .max(
                  maxTableNumber,
                  intl.formatMessage(
                    {
                      defaultMessage:
                        'Please use a {text} between {minTableNumber} and {maxTableNumber}',
                      id: 'p7bTx6',
                    },
                    {
                      text: tableText.toLowerCase(),
                      minTableNumber: minTableNumber,
                      maxTableNumber: maxTableNumber,
                    },
                  ),
                )
          }
      }
    })(),
    tableArea: hasTableAreas
      ? Yup.string().nullable().required('Please select an option')
      : Yup.string().nullable(),
  })
}

export const getTableNumberRequirement = (
  venue?: VenueTableNumberQuery['guestVenue'],
) => venue?.orderingType?.hasTableNumber || VenueFieldRequirement.Hidden

export const getTableNumberSettings = (
  { formatMessage }: IntlShape,
  venue?: VenueTableNumberQuery['guestVenue'],
) => {
  let tablePromptText: string | undefined = ''
  let tableText: string | undefined = ''
  let tableAreaText: string | undefined = ''
  let tableNumberValidation: TableNumberValidation
  let minTableNumber
  let maxTableNumber
  let tableAreas: string[] = []
  let usingTableGroups = false

  const orderingType = venue?.orderingType

  if (orderingType) {
    tablePromptText =
      orderingType.tablePromptText ||
      formatMessage({ defaultMessage: 'Where are you located?', id: 'Tse7aG' })
    tableText = orderingType.tableText
    tableAreaText = orderingType.tableAreaText
    tableNumberValidation =
      orderingType.tableNumberValidation || TableNumberValidation.Any
    minTableNumber = orderingType.minTableNumber || DEFAULT_MIN_TABLE_NUMBER
    maxTableNumber = orderingType.maxTableNumber || DEFAULT_MAX_TABLE_NUMBER
    tableAreas = orderingType.tableAreas || []
    usingTableGroups = !!orderingType.usingTableGroups
  } else {
    tableNumberValidation = TableNumberValidation.Any
    minTableNumber = DEFAULT_MIN_TABLE_NUMBER
    maxTableNumber = DEFAULT_MAX_TABLE_NUMBER
  }

  return {
    tablePromptText,
    tableText,
    tableAreaText,
    tableNumberValidation,
    minTableNumber,
    maxTableNumber,
    tableAreas,
    usingTableGroups,
  }
}
