import { navigate } from '@mr-yum/frontend-core/dist/services/routes'
import {
  CenterSpinner,
  ChevronRightIcon,
  ErrorNotification,
} from '@mr-yum/frontend-ui'
import { ResponsiveSheet } from 'components/ResponsiveSheet'
import {
  OrderingTypeItem,
  venueToOrderingTypeItems,
} from 'components/Venue/utils/venueUtils'
import {
  useOrderingTypeContext,
  useVenueContext,
} from 'contexts/VenueOrderContext'
import { OrderingType, VenueLandingQuery } from 'gql/graphql'
import { useLogger } from 'hooks/useLogger'
import { getOrderingSlugFromType, routes } from 'lib/routes'
import React, { useCallback, useContext, useEffect } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { CustomerStoreContext } from 'stores/CustomerStore'

interface Props {
  open: boolean
  venue: VenueLandingQuery['guestVenue']
  cartFetching: boolean
  cartStale: boolean
  cartError?: Error | null
  onOpenChange: () => void
}

interface OrderingTypeLinkProps {
  venueSlug: string
  item: OrderingTypeItem
  onClick: (orderingType: OrderingType) => void
}

const OrderingTypeLink = ({ onClick, item }: OrderingTypeLinkProps) => {
  return (
    <a
      onClick={() => onClick(item.orderingType)}
      className="flex cursor-pointer items-center justify-between p-4 text-interactive-foreground transition-colors hover:bg-interactive-surface-hover"
      aria-label={item.orderingType}
    >
      <div>
        <span className="my-label-md">{item.name}</span>

        {!item.isEnabled && (
          <span className="text-interactive-subtle-foreground my-body-md">
            {' '}
            (<FormattedMessage defaultMessage="not available" id="lmLoBx" />)
          </span>
        )}
      </div>

      <ChevronRightIcon />
    </a>
  )
}

export const OrderingTypeSheet = ({
  open,
  venue,
  cartFetching,
  cartStale,
  cartError,
  onOpenChange,
}: Props) => {
  const intl = useIntl()
  const { logEvent } = useLogger()
  const { orderingType } = useOrderingTypeContext()
  const { venueSlug } = useVenueContext()

  const { showOrderingTypeModal } = useContext(CustomerStoreContext)

  useEffect(() => {
    showOrderingTypeModal()
  }, [showOrderingTypeModal])

  const navigateToOrderingType = useCallback(
    (selectedOrderingType: OrderingType) => {
      return navigate(
        routes.menuCategories,
        {
          venueSlug,
          orderingTypeSlug: getOrderingSlugFromType(selectedOrderingType),
        },
        {
          shallow: false,
        },
      )
    },
    [venueSlug],
  )

  const { items } = venueToOrderingTypeItems(venue, orderingType, intl)

  const handleOnClick = useCallback(
    async (selectedOrderingType: OrderingType) => {
      logEvent('Clicked on order type selector and changed their order type')
      void navigateToOrderingType(selectedOrderingType)
    },
    [logEvent, navigateToOrderingType],
  )

  return (
    <ResponsiveSheet
      open={open}
      title={
        <FormattedMessage
          defaultMessage="How would you like to order?"
          id="k5pSuS"
        />
      }
      onOpenChange={onOpenChange}
    >
      <div className="relative">
        {cartError && <ErrorNotification fullWidth error={cartError} />}

        {(cartFetching || cartStale) && (
          <div className="absolute inset-0 flex items-center justify-center bg-white/50">
            <CenterSpinner />
          </div>
        )}

        <div className="divide-y">
          {items.map((item) => (
            <OrderingTypeLink
              venueSlug={venueSlug}
              key={item.id}
              item={item}
              onClick={handleOnClick}
            />
          ))}
        </div>
      </div>
    </ResponsiveSheet>
  )
}
