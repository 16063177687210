import { getRequestId } from '@mr-yum/frontend-core/dist/support/getRequestId'
import { urqlFetchWithLogging } from '@mr-yum/frontend-core/dist/support/urql'
import { createClient, fetchExchange, Operation } from '@urql/core'
import { devtoolsExchange } from '@urql/devtools'
import { Cache, cacheExchange } from '@urql/exchange-graphcache'
import { requestPolicyExchange } from '@urql/exchange-request-policy'
import { retryExchange } from '@urql/exchange-retry'
import { CloseBillResponse } from 'gql/graphql'
import { IncomingMessage, ServerResponse } from 'http'
import find from 'lodash/fp/find'
import flow from 'lodash/fp/flow'
import get from 'lodash/fp/get'
import { withUrqlClient } from 'next-urql'
import { getNestedVenueSlug } from 'utils/venue'

import { appVersion, config } from './config'
import { appendCookie, appendSetCookie, getGuestGatewayUrl } from './utils'

/**
 * We want to fetch some queries only once
 * and not upgrade them to `cache-and-network` every 2mins
 */
const QUERY_UPGRADE_BLACKLIST = [
  'googleTagManagerId',
  'venueFacebookPixelId',
  'venueBrandingColors',
  'venueNotificationModal',
  'venueMetaImage',
  'venueCountryCode',
  'aboutVenue',
  'hamburgerMenu',
  'venueName',
  'venueAddressValidation',
  'getVenueCurrency',
]

const invalidate = (fieldNames: string | string[], cache: Cache) => {
  const cartQueries = cache
    .inspectFields('Query')
    .filter((x) => fieldNames.includes(x.fieldName))

  cartQueries.forEach(({ fieldName, arguments: variables }) => {
    cache.invalidate('Query', fieldName, variables || undefined)
  })
}

const retryOperations = ['paymentV2Meta', 'venueTags']

// Additional logging for development environment
const urqlFetch =
  config.environment === 'development'
    ? (urqlFetchWithLogging as typeof fetch)
    : fetch

const contextFetch =
  (ctx?: { req?: IncomingMessage; res?: ServerResponse }): typeof fetch =>
  async (...args) => {
    const res = await urqlFetch(...args)

    // for client side we don't need to do anything, hence early return
    if (typeof window !== 'undefined' || !ctx) {
      return res
    }

    // server side
    const setCookie = res.headers.get('set-cookie')
    if (setCookie) {
      // set set-cookie to context response, so it gets relayed back to client side
      if (ctx.res) {
        appendSetCookie(ctx.res, setCookie)
      }

      // store the cookie, for subsequent SSR requests
      if (ctx.req) {
        const cookie = setCookie.split(';')[0] // we ignore set-cookie attributes as we are not going to persist the cookie beyond the current session anyway
        appendCookie(ctx.req, cookie)
      }
    }

    return res
  }

export const urqlClient = (
  venueSlug: string,
  req?: IncomingMessage,
  res?: ServerResponse,
) =>
  createClient({
    url:
      typeof window !== 'undefined'
        ? getGuestGatewayUrl(
            config.environment,
            config.region,
            window.location.host,
          )
        : `${config.apiUrl}/graphql`,
    fetch: contextFetch({ req, res }),
    fetchOptions: () => {
      const headers: Record<string, string> = {
        'content-type': 'application/json',
        'apollographql-client-name': 'web',
        'apollographql-client-version': appVersion,
        'x-mryum-slug': venueSlug,
        // seemingly getting this issue with the 3DS modal locally after this change
        // https://stackoverflow.com/questions/57226104/cannot-set-headers-after-they-are-sent-to-the-client-with-express-validator-exp
        ...(process.env.APP_ENV !== 'development' &&
        req?.headers['x-forwarded-host']
          ? {
              'x-forwarded-host': String(req?.headers['x-forwarded-host']),
            }
          : {}),
      }

      const id = req && getRequestId(req)
      if (id) {
        headers['x-request-id'] = id
      }

      const cookie = req && req.headers.cookie
      if (cookie) {
        headers['cookie'] = cookie
      }

      return {
        credentials: 'include',
        headers,
      }
    },
    exchanges: [cacheExchange({}), fetchExchange],
    preferGetMethod: true,
    maskTypename: true,
  })

export const withUrql = withUrqlClient((ssrExchange, ctx) => ({
  url:
    typeof window !== 'undefined'
      ? getGuestGatewayUrl(
          config.environment,
          config.region,
          window.location.host,
        )
      : `${config.apiUrl}/graphql`,
  fetch: contextFetch(ctx),
  fetchOptions: () => {
    const headers: Record<string, string> = {
      'content-type': 'application/json',
      'apollographql-client-name': 'web',
      'apollographql-client-version': appVersion,
      // seemingly getting this issue with the 3DS modal locally after this change
      // https://stackoverflow.com/questions/57226104/cannot-set-headers-after-they-are-sent-to-the-client-with-express-validator-exp
      ...(process.env.APP_ENV !== 'development' &&
      ctx?.req?.headers['x-forwarded-host']
        ? {
            'x-forwarded-host': String(ctx?.req?.headers['x-forwarded-host']),
          }
        : {}),
    }

    const id = ctx?.req && getRequestId(ctx.req)
    if (id) {
      headers['x-request-id'] = id
    }

    const cookie = ctx?.req && ctx.req.headers.cookie
    if (cookie) {
      headers['cookie'] = cookie
    }

    // Context is only available server side
    if (typeof window === 'undefined') {
      // this should be defined though as we don't make API requests unless venueSlug is present (see app.tsx)
      const venueSlug = ctx?.query ? getNestedVenueSlug(ctx.query) : ''
      headers['x-mryum-slug'] = venueSlug ?? ''

      // For client side fetch requests we get venue slug from cookie
      // that was returned on initial bundle download response
    } else {
      const venueSlug = getNestedVenueSlug(window.location.pathname)
      headers['x-mryum-slug'] = venueSlug ?? ''
    }

    return {
      credentials: 'include',
      headers,
    }
  },
  exchanges: [
    devtoolsExchange,
    requestPolicyExchange({
      ttl: 2 * 60 * 1000, // 2 minutes in ms
      shouldUpgrade: (op: Operation) => {
        const name = flow(
          find({ kind: 'OperationDefinition' }),
          get('name.value'),
        )(op.query.definitions)
        const shouldUpgrade = !QUERY_UPGRADE_BLACKLIST.includes(name)
        return shouldUpgrade
      },
    }),
    cacheExchange({
      requestPolicy: 'cache-only',
      updates: {
        Mutation: {
          checkForAndApplyLoyaltyDiscounts: (
            result: {
              checkForAndApplyLoyaltyDiscounts: {
                discountsApplied: boolean
                errors: string[]
              }
            },
            _args,
            cache,
          ) => {
            if (
              result.checkForAndApplyLoyaltyDiscounts?.discountsApplied ||
              result.checkForAndApplyLoyaltyDiscounts?.errors?.length > 0
            ) {
              invalidate('getCart', cache)
            }
          },
          // Refetch cart when user changes the table number
          addOrUpdateTableNumberOnCart: (_result, _args, cache) => {
            invalidate('getCart', cache)
          },

          createReview: (_result, _args, cache) => {
            invalidate('getReview', cache)
            invalidate('getShowReviewModal', cache)
          },

          createCart: (_result, _args, cache) => {
            invalidate('getCart', cache)
          },

          // Refetch cart when user changes the address
          addOrUpdateLocationOnCart: (_result, _args, cache) => {
            invalidate('getCart', cache)
          },

          // Refetch cart when user changes the ordering window
          addOrUpdateWindowOnCart: (_result, _args, cache) => {
            invalidate('getCart', cache)
          },

          // Refetch cart when a eonxLogin is fired
          eonxLoginWithPartner: (_result, _args, cache) => {
            invalidate('getCart', cache)
          },

          updateCartItemQuantity: (_result, _args, cache) => {
            invalidate('computePointsToUse', cache)
            invalidate('getCart', cache)
          },

          removeItemFromCart: (_result, _args, cache) => {
            invalidate('computePointsToUse', cache)
            invalidate('getCart', cache)
          },

          // Refetch currentUser when user logs in
          login: (_result, _args, cache) => {
            invalidate('currentUser', cache)
          },

          closeBill: (_result: CloseBillResponse, _args, cache) => {
            invalidate(
              [
                'getOrderHistoryV3',
                'currentUser',
                'getTab',
                'getBillWithSummary',
              ],
              cache,
            )
          },

          setSpendLimit: (_res, _args, cache) => {
            invalidate(['getOrderHistoryV3', 'getBillWithSummary'], cache)
          },

          // Legacy flow: Refetch when user creates an order
          createOrder: (_result, _args, cache) => {
            invalidate(
              [
                'lastOrderByUserByVenueSlug',
                'getAnotherRoundCartItems',
                'getBillWithSummary',
                'getOrderHistoryV3',
              ],
              cache,
            )
          },
          // Charge has been deprecated and will eventually be removed
          charge: (_result, _args, cache) => {
            invalidate(
              [
                'lastOrderByUserByVenueSlug',
                'getAnotherRoundCartItems',
                'getBillWithSummary',
                'getOrderHistoryV3',
              ],
              cache,
            )
          },
          chargeBill: (_result, _args, cache) => {
            invalidate(
              [
                'lastOrderByUserByVenueSlug',
                'getAnotherRoundCartItems',
                'getBillWithSummary',
                'getOrderHistoryV3',
              ],
              cache,
            )
          },
          voidAndCreateOrderIntent: (_result, _args, cache) => {
            invalidate('getCart', cache)
          },
        },
      },
      keys: {
        Cuisine: () => null,
        MenuCategory: () => null,
        MenuCategoryToMenuSection: () => null,
        MenuItem: () => null,
        MenuItemToItemUpsellGroup: () => null,
        MenuItemToMenuSection: () => null,
        CdnImage: () => null,
        TimeSlot: () => null,
        PriceData: () => null,
        VerifyCharge: () => null,
        GuestPriceData: () => null,
        ModifierToModifierGroup: () => null,
        CartTotal: () => null,
        AvailableSlot: () => null,
        PaymentProcessor: () => null,
        Cart: () => '1',
        GuestVenuePaymentProcessor: () => null,
        GuestMenuItem: () => null,
        GuestRewardPrice: () => null,
        TableRule: (data) =>
          `${data?.area || 'no-area'}::${data?.number || 'no-number'}`,
        CartValidationErrorV2: () => null,
        GetPaymentsResponse: () => null,
        ListBillOrdersResponse: () => null,
        ListBillsResponse: () => null,
        SupportedPaymentProcessor: () => null,
        CartServiceCharge: () => null,
      },
    }),
    ssrExchange,
    // @ts-expect-error complains about types but seems to work fine. Probably this issue: https://github.com/urql-graphql/urql/issues/1017
    retryExchange({
      initialDelayMs: 1000,
      maxDelayMs: 15000,
      maxNumberAttempts: 5,
      retryIf: (_err, operation) => {
        const isPaymentProcessorsReq = operation.query.definitions.find(
          (gqlDefinition) =>
            gqlDefinition.kind === 'OperationDefinition' &&
            gqlDefinition.name?.value &&
            retryOperations.includes(gqlDefinition.name?.value),
        )

        return !!isPaymentProcessorsReq
      },
    }),
    fetchExchange,
  ],
  preferGetMethod: true,
}))
