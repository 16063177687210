import { FLAG_DISABLE_REORDER_DRINKS } from 'common/constants'
import { useEonxEnvContext } from 'components/EonX/EonxEnv'
import {
  OrderingType,
  VenueLandingAdditionalQuery,
  VenueLandingQuery,
} from 'gql/graphql'
import { useFeatureFlag } from 'hooks/useFeatureFlag'
import { isLokeOrigin } from 'hooks/useLoke'
import { observer } from 'mobx-react-lite'
import React, { ReactNode } from 'react'

import { getReorderId } from '../utils/venueUtils'
import { LoyaltyMembershipBlock } from './LoyaltyMembershipBlock'
import { ManageTabBlock } from './ManageTabBlock'
import { ReorderBlock } from './ReorderBlock'
import { StartTabBlock } from './StartTabBlock'

interface Props {
  venueSlug: string
  venue: VenueLandingQuery['guestVenue']
  orderingType: OrderingType
  tabs: { id: string }[]
  additionalInfoData: VenueLandingAdditionalQuery
  children?: ({
    reorderId,
    lastOrder,
  }: {
    reorderId: string
    lastOrder: NonNullable<
      VenueLandingAdditionalQuery['lastOrderByUserByVenueSlug']
    >
  }) => ReactNode
}

export const ActionBlocks = observer(
  ({
    venueSlug,
    venue,
    orderingType,
    additionalInfoData,
    children,
    tabs,
  }: Props) => {
    const { isEonxEnv } = useEonxEnvContext()
    const { value: disableReorderDrinks } = useFeatureFlag(
      FLAG_DISABLE_REORDER_DRINKS,
    )

    const availableForOrderingType = [
      OrderingType.DineIn,
      OrderingType.Counter,
    ].includes(orderingType)

    const hasTab =
      !!venue?.startTabAvailable &&
      availableForOrderingType &&
      !isLokeOrigin() &&
      !isEonxEnv

    // Loyalty membership logic
    const loyaltyDetails = additionalInfoData.loyaltyDetails

    const hasLoyalty = loyaltyDetails && loyaltyDetails?.metadata?.infoLink

    const { reorderId } = getReorderId(
      additionalInfoData.lastOrderByUserByVenueSlug,
      venue,
      orderingType,
    )

    const count = [hasTab, hasLoyalty, reorderId].filter(Boolean).length

    if (!count || venue?.isClosed) return null

    return (
      <>
        <div className="-mr-4 mb-6 flex gap-3 overflow-x-auto whitespace-nowrap pr-4 scrollbar-hide md:mr-0 md:pr-0">
          {hasTab && tabs.length === 0 && (
            <StartTabBlock venueSlug={venueSlug} count={count} />
          )}

          {hasTab && tabs.length > 0 && (
            <ManageTabBlock
              venueSlug={venueSlug}
              count={count}
              tabId={tabs[0].id}
            />
          )}

          {hasLoyalty && (
            <LoyaltyMembershipBlock
              venueSlug={venueSlug}
              memberName={loyaltyDetails?.metadata?.memberName}
              count={count}
            />
          )}

          {!disableReorderDrinks && reorderId && (
            <ReorderBlock
              venueSlug={venueSlug}
              orderId={reorderId}
              count={count}
            />
          )}
        </div>
        {additionalInfoData.lastOrderByUserByVenueSlug &&
          reorderId &&
          children &&
          children({
            reorderId,
            lastOrder: additionalInfoData.lastOrderByUserByVenueSlug,
          })}
      </>
    )
  },
)

ActionBlocks.displayName = 'ActionBlocks'
