import { CartLandingQuery, CartMenuQuery } from 'gql/graphql'

/**
 * Get total item count in cart, including upsells
 */
export const getCartItemCount = (
  cart: CartMenuQuery['getCart'] | CartLandingQuery['getCart'],
): number => {
  return (
    cart?.cartItems.reduce((total, lineItem) => {
      const upsellCount = (lineItem?.cartUpsells || []).reduce(
        (upsellTotal, upsell) => {
          return upsellTotal + upsell.quantity
        },
        0,
      )
      return total + upsellCount + lineItem.quantity
    }, 0) || 0
  )
}
