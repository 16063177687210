import {
  GuestImage,
  MenuCategoryType,
  MvoSearchQuery,
  MvoSearchResultMenuItemFragment,
  OrderingType,
} from 'gql/graphql'
import { MenuItemPartsFragment } from 'lib/gql.types'

export type SearchResultsToDisplay = {
  categoryId: string
  categoryName: string
  categorySlug: string
  isClosed: boolean
  menuCategoryType: MenuCategoryType
  image?: GuestImage
  mvoFeatured?: boolean
  orderingType?: OrderingType[]
  position?: number
  menuItems?: MvoSearchResultMenuItemFragment[]
}

export const isImageItem = (
  item: MvoSearchResultMenuItemFragment,
): item is MenuItemPartsFragment => {
  return !!item.image && item.type === 'menu'
}

export const isListItem = (
  item: MvoSearchResultMenuItemFragment,
): item is MenuItemPartsFragment => {
  return !item.image && item.type === 'list'
}

const alphabetize = (a: SearchResultsToDisplay, b: SearchResultsToDisplay) => {
  const nameA = a.categoryName.toUpperCase()
  const nameB = b.categoryName.toUpperCase()
  if (nameA < nameB) {
    return -1
  }
  if (nameA > nameB) {
    return 1
  }

  return 0
}

export const filterAndSortResults = (
  vendorsWithItems?: MvoSearchQuery['mvoItemSearch'],
  vendors?: MvoSearchQuery['mvoVendorSearch'],
): SearchResultsToDisplay[] => {
  const filteredVendors: SearchResultsToDisplay[] = []
  const vWISlugs: string[] = []
  if (vendorsWithItems) {
    for (const vWI of vendorsWithItems) {
      const filteredItems = vWI.menuItems.filter((item) =>
        ['menu', 'list'].includes(item.type!),
      )

      filteredVendors.push({
        categoryId: vWI.categoryId,
        categoryName: vWI.categoryName,
        categorySlug: vWI.categorySlug,
        isClosed: vWI.isClosed,
        menuCategoryType: vWI.menuCategoryType,
        image: vWI.image ?? undefined,
        mvoFeatured: vWI.mvoFeatured ?? undefined,
        orderingType: vWI.orderingType ?? undefined,
        position: vWI.position ?? undefined,
        menuItems: filteredItems,
      })

      vWISlugs.push(vWI.categorySlug)
    }
  }

  if (vendors) {
    for (const v of vendors) {
      const isInVWI = vWISlugs.includes(v.slug)

      if (!isInVWI) {
        filteredVendors.push({
          categoryId: v.id,
          categoryName: v.name,
          categorySlug: v.slug,
          isClosed: v.isClosed,
          menuCategoryType: v.menuCategoryType,
          image: v.image ?? undefined,
          mvoFeatured: v.mvoFeatured ?? undefined,
          orderingType: v.orderingType ?? undefined,
          position: v.position ?? undefined,
        })
      }
    }
  }

  const alphabetizedResults = filteredVendors.sort(alphabetize)

  return alphabetizedResults
}
